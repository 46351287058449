import useTranslation from 'hooks/useTranslation';
import React from 'react';
import { useSelector } from 'react-redux';

import generalStyle from '../../layouts/auth/index.style';
import ConfirmEmailMessage from '../utils/ConfirmEmailMessage';
import LoginForm from './LoginForm';

function Login() {
  const { TRANSLATIONS } = useTranslation();
  const UI = useSelector((state) => state?.UI) || [];
  const message = UI?.show_confirmation_email_message;
  const confirmationEmail = UI?.confirmation_email;

  return (
    <div className="login-content">
      <div className="title">
        <h1>{TRANSLATIONS.auth.login}</h1>
      </div>
      {message && <ConfirmEmailMessage userEmail={confirmationEmail} />}
      <LoginForm />
      <style jsx>{generalStyle}</style>
      <style jsx>{`
        .login-content {
          min-height: 50vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          width: 100%;
        }
        .title {
          margin-bottom: 2em;
        }
        .title h1 {
          margin: 0;
          font-size: 40px;
          font-weight: 600;
          line-height: 35px;
        }
        @media only screen and (max-width: 425px) {
          .title {
            margin-bottom: 1em;
          }
          .title h1 {
            margin: 0;
            font-size: 22px;
            font-weight: 600;
            line-height: 25px;
          }
          .title p {
            margin-top: 5px;
            font-size: 15px;
          }
        }
        .link-wrapper {
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
          text-align: center;
        }
        .link-wrapper span {
        }
        .link-wrapper :global(a) {
          color: var(--primary) !important;
          font-weight: 500;
        }
      `}</style>
    </div>
  );
}

export default Login;
