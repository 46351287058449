import Sidebar from 'components/auth/Sidebar';
import SelectLanguage from 'components/generic/SelectLanguage';
import SelectTheme from 'components/generic/SelectTheme';
import { useIsEmbedded } from 'hooks/useIsEmbedded';
import useTranslation from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { isServer } from 'tools/utils';
import { useEffect } from 'react';

function Auth({ children }) {
  const router = useRouter();
  const { TRANSLATIONS, translationsParameters } = useTranslation();
  const PERMISSIONS = useSelector((state) => state.permissions);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const userIsLoading = useSelector((state) => state.userIsLoading);
  const isEmbedded = useIsEmbedded();
  const redirectUrl = useSelector((state) => state.UI.redirectUrl);
  const dispatch = useDispatch();
  const channelcode = useSelector((state) => state.UI.channelcode);

  useEffect(() => {
    if (
      !isServer() &&
      isLoggedIn &&
      channelcode !== 'wix' &&
      channelcode !== 'shopify'
    ) {
      if (!redirectUrl) {
        router.push('/home');
      } else {
        router.push(redirectUrl);
      }
    }
  }, [channelcode, dispatch, isLoggedIn, redirectUrl, router]);

  if (userIsLoading) {
    return null;
  }
  const conditionMessage =
    TRANSLATIONS.extraField.message !== null && !isEmbedded;

  return (
    <div className="layout-auth">
      {conditionMessage && (
        <div className={`top-banner top-banner-max`}>
          <div className={'top-banner-message-box d-f fd-c ai-c'}>
            <p className={'ta-c'}>
              {translationsParameters(TRANSLATIONS.extraField.message, [
                // eslint-disable-next-line react/jsx-key
                <a href={TRANSLATIONS.extraField.href} rel="noreferrer">
                  {TRANSLATIONS.extraField.cta}
                </a>,
              ])}
            </p>
          </div>
        </div>
      )}
      <div className="page-user">
        <main>
          <div className="container">
            <div className="main-wrapper">
              <div className="header margin-content">
                <div className="logo" />
                <div className="select-theme">
                  <div>
                    <SelectTheme />
                  </div>
                  <div>
                    <SelectLanguage />
                  </div>
                </div>
              </div>
              <div className="content">{children}</div>
            </div>
          </div>
          <div className="sidebar-wrapper">
            <Sidebar />
          </div>
        </main>
      </div>
      <style jsx>{`
        .top-banner {
          z-index: 8;
          box-sizing: border-box;
          align-items: center;
          padding: 4px;
          background: var(--banner-top);
        }
        .top-banner-message-box p {
          color: var(--top-banner-p);
          font-size: 13px;
          font-weight: 500;
        }
        @media only screen and (min-width: 992px) {
          .top-banner {
            z-index: 8;
            box-sizing: border-box;
            align-items: center;
            padding: 0px;
            background: var(--banner-top);
            position: absolute;
          }
          .top-banner {
            width: 100%;
          }
          .top-banner-message-box p {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 300;
            letter-spacing: 0.5px;
          }
          .top-banner-message-box a {
            color: var(--top-banner-p);
            font-size: 13px;
            font-weight: 700;
            text-decoration: underline;
          }
          .top-banner-message-box {
            padding: 0.4em;
            width: 100%;
          }
        }

        @media only screen and (min-width: 992px) {
          .top-banner {
            top: 0px;
          }
          .margin-content {
            margin-top: 50px;
          }
        }
        .page-user {
          padding: 50px 0 20px;
          background: var(--background);
          min-height: 100vh;
          height: 100%;
        }
        .main-wrapper {
          max-width: 500px;
          padding: 0 15px;
          margin: 0 auto;
        }
        .header {
          display: flex;
        }
        .select-theme {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .select-theme > div {
          margin: 0 5px;
        }
        .header .logo {
          background-image: var(--logo-login);
          height: 50px;
          background-repeat: no-repeat;
          width: 100%;
        }
        .header .logo img {
        }
        .content {
          padding: 50px 0 0;
        }
        .sidebar-wrapper {
        }
        @media all and (min-width: 992px) {
          .page-user {
            max-height: 100%;
            overflow: hidden;
          }
          .main-wrapper {
            margin: 0;
          }
          .main-wrapper {
            min-height: calc(100vh - 92px);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .content {
            height: 100%;
            align-items: center;
            display: flex;
            padding: 45px 0px !important;
          }
        }
        .ls-4 {
          letter-spacing: 0.4px;
        }
      `}</style>
    </div>
  );
}

export default Auth;
