import { login, loginSocial } from 'actions/user';
import Checkbox from 'components/utils/Checkbox';
import { Form, Password, Select, Submit, Text } from 'components/utils/form';
import Icon from 'components/utils/Icon';
import useTranslation, { mergeTranslation } from 'hooks/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useResponsive from '../../hooks/useResponsive';
import { LANGUAGES, LOGIN_ERRORS } from '../../tools/constants';
import countries from '../../tools/constants/countries.json';
import message from '../utils/message';
import Modal from '../utils/Modal';
import Tooltip from '../utils/Tooltip';
import SocialButton from '../utils/SocialButton';
import { useGoogleLogin } from '@react-oauth/google';
import TagManager from 'react-gtm-module';
import { setRedirectUrl } from 'actions/ui';
import {
  getLocaleTranslation,
  setLangDescription,
  setLocale,
  setTranslation,
} from 'actions/translations';
import { setLocalStorage } from '../../tools/localStorage';
import { createCatalog } from 'actions/channel';

function LoginForm({}) {
  const { TRANSLATIONS, locale } = useTranslation();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const { redirectUrl } = router.query;
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const showPassword = useCallback(() => {
    setShow(!show);
  }, [show]);
  const [socialUser, setSocialUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile } = useResponsive();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [googleError, setGoogleError] = useState(null);
  const countryList = useMemo(
    () =>
      countries.map(({ country, id }) => ({
        label: country,
        value: id,
      })),
    []
  );
  const user = useSelector((state) => state?.user);
  const channelcode = useSelector((state) => state.UI.channelcode);

  useEffect(() => {
    if (redirectUrl) {
      const url = decodeURIComponent(redirectUrl);
      dispatch(setRedirectUrl({ redirectUrl: url }));
    }
  }, [dispatch, redirectUrl]);

  const setLanguageFromCountry = useCallback(
    (locale) => {
      if (Object.keys(LANGUAGES).includes(locale)) {
        dispatch(setLocale(locale));
        setLocalStorage(`cachedLocale`, locale);
        setLocalStorage(`lang_description`, locale);
        dispatch(setLangDescription(locale));
        dispatch(
          getLocaleTranslation(
            locale,
            (translations) => {
              dispatch(setTranslation(mergeTranslation(translations || {})));
            },
            () => {}
          )
        );
      }
    },
    [dispatch]
  );
  function onSubmit(data) {
    setLoading(true);
    dispatch(
      login(
        data,
        (userCatalogs) => {
          if (userCatalogs?.length === 0) {
            const currency =
              countries.find(
                (country) => country.countrycode === user.countrycode
              )?.currency || 'EUR';
            dispatch(
              createCatalog({
                ids: [],
                connections: [
                  {
                    service: 'socialEcommerce',
                    component: 'socialEcommerce',
                  },
                ],
                name: 'Wishlist',
                language: locale,
                currency,
                minQuantity: 1,
                allProducts: true,
              })
            );
          }
          setLoading(false);
          TagManager.dataLayer({
            dataLayer: {
              event: 'Login',
              method: 'Email',
              userEmail: data?.email?.toLowerCase(),
            },
          });
        },
        (errorType) => {
          if (errorType === LOGIN_ERRORS.CREDENTIALS) {
            message.error(TRANSLATIONS.auth.wrongCredentials, { duration: 5 });
          }
          if (errorType === LOGIN_ERRORS.ROLE_EXPIRED) {
            message.error(TRANSLATIONS.auth.roleExpired, { duration: 5 });
          }
          if (errorType === LOGIN_ERRORS.FORCE_TO_RESET_PASSWORD) {
            message.error(TRANSLATIONS.auth.forceToResetPassword, {
              duration: 5,
            });
            router.push('/forgot');
          }
          setLoading(false);
        }
      )
    );
  }

  const handleSocialLogin = useCallback(
    (user, values) => {
      setLoading(true);
      let data = {
        type: user.provider,
        token: user.token.accessToken,
      };
      if (socialUser) {
        const country = countries.find((c) => c.id === selectedCountry);
        data = {
          ...data,
          countryCode: country?.countrycode,
          countryId: country?.id,
          localeCode: country?.localecode || 'en_US',
          alreadySell: values.alreadySell === 'Yes',
          phone: {
            pref: values.prefix,
            num: values.phoneNumber,
          },
          website: values.website,
          description: values.description,
          DidYouKnowBDroppy:
            channelcode || data?.DidYouKnowBDroppy // no_profilation
              ? channelcode || data?.DidYouKnowBDroppy
              : 'no_profilation',
          lang: Object.keys(LANGUAGES).includes(country?.localecode)
            ? country?.localecode?.toUpperCase()
            : locale.toUpperCase(),
          limitTarsCount: 1000,
          newsletters: false,
        };
        setLanguageFromCountry(data?.localeCode);
      }
      dispatch(
        loginSocial(
          data,
          (response) => {
            if (response?.data?.missingFields) {
              setSocialUser(user);
              setModalOpen(true);
            } else {
              if (response.length === 0) {
                const currency =
                  countries.find(
                    (country) => country.countrycode === user.countrycode
                  )?.currency || 'EUR';
                dispatch(
                  createCatalog({
                    ids: [],
                    connections: [
                      {
                        service: 'socialEcommerce',
                        component: 'socialEcommerce',
                      },
                    ],
                    name: 'Wishlist',
                    language: user?.localeCode || locale,
                    currency,
                    minQuantity: 1,
                    allProducts: true,
                  })
                );
              }
            }
            setLoading(false);
          },
          (errorType) => {
            if (errorType === LOGIN_ERRORS.CREDENTIALS) {
              message.error(TRANSLATIONS.auth.wrongCredentials, {
                duration: 5,
              });
            }
            if (errorType === LOGIN_ERRORS.ROLE_EXPIRED) {
              message.error(TRANSLATIONS.auth.roleExpired, { duration: 5 });
            }
            if (errorType === LOGIN_ERRORS.NO_EMAIL_FROM_SOCIAL) {
              message.error(TRANSLATIONS.auth.noEmailFromSocial, {
                duration: 10,
              });
            }
            setLoading(false);
          },
          !!socialUser
        )
      );
    },
    [
      TRANSLATIONS.auth.noEmailFromSocial,
      TRANSLATIONS.auth.roleExpired,
      TRANSLATIONS.auth.wrongCredentials,
      channelcode,
      dispatch,
      locale,
      selectedCountry,
      setLanguageFromCountry,
      socialUser,
    ]
  );

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      handleSocialLogin({
        provider: 'google',
        token: { accessToken: codeResponse?.access_token },
      });
    },
    onError: (err) => {
      handleSocialLoginFailure(err);
    },
    flow: 'implicit',
  });

  const handleSocialLoginFailure = useCallback((err) => {
    if (err?.message?.includes('google')) {
      if (!err?.message?.includes('popup_closed_by_user')) {
        if (
          err.message.includes(
            'Cookies are not enabled in current environment.'
          )
        ) {
          setGoogleError('enableCookies');
        } else {
          setGoogleError('genericError');
        }
      }
    }
  }, []);

  return (
    <div className="login-form">
      <Modal
        openModal={modalOpen}
        onClose={() => {
          router.reload(window.location.pathname);
        }}
        width={isMobile ? '100%' : '750px'}
        scroll={false}
      >
        <div className="jc-c register-modal g-20 fd-c d-f">
          <h2>{TRANSLATIONS.auth.welcome}</h2>
          <div>
            <p>{TRANSLATIONS.auth.fewQuestion}</p>
          </div>
          <Form onSubmit={(data) => handleSocialLogin(socialUser, data)}>
            <div className="mb-20">
              <h4>{TRANSLATIONS.auth.selectCountry}</h4>
              <div className="country">
                <Select
                  name="country"
                  options={countryList}
                  placeholder={TRANSLATIONS.auth.selectCountry}
                  clearable={false}
                  defaultValue={selectedCountry}
                  onChange={setSelectedCountry}
                  required
                />
              </div>
            </div>
            <Submit
              label={TRANSLATIONS.auth.completeSocialRegistration}
              className="large button primary wide"
              disableOnValidation
            ></Submit>
          </Form>
        </div>
      </Modal>

      <Form onSubmit={onSubmit}>
        <div className="d-f g-10 fd-c">
          <Text name="email" placeholder="Email" className="large" />
          <Password
            name="password"
            placeholder="Password"
            className=""
            type={show ? 'text' : 'password'}
            endAdornment={
              <Icon
                onClick={() => showPassword()}
                type={show ? 'eye-off' : 'eye'}
              />
            }
          />
        </div>
        <div className="form-links mt-md">
          <div className="remember-me">
            <Checkbox label={TRANSLATIONS.auth.keep} name="remember" />
          </div>
          <div className="forgot-password">
            <Link href="/forgot">{TRANSLATIONS.auth.forget}</Link>
          </div>
        </div>
        <Submit className="primary large wide" loading={loading}>
          {TRANSLATIONS.auth.login}
          {!loading && <Icon type="arrow-right" />}
        </Submit>
        <div className="link-wrapper">
          <span>{TRANSLATIONS.auth.registered} </span>
          <Link href="/register">{`${TRANSLATIONS.auth.signup}`}</Link>
        </div>
      </Form>
      <div className="divider"></div>

      <div className="social-buttons-container">
        {googleError && (
          <Tooltip
            className={'bottom'}
            containerClassName={'container-tooltip'}
            text={
              googleError === 'enableCookies'
                ? TRANSLATIONS.auth.enableCookies
                : TRANSLATIONS.auth.genericGoogleError
            }
          >
            <button className="social-button disabled-social-button">
              <div className="box-image">
                <img src="/images/social-network/google.svg" alt="" />
              </div>
              {TRANSLATIONS.auth.googleLogin}
            </button>
          </Tooltip>
        )}
        {/*
            <SocialButton
          provider="google"
          appId={process.env.GOOGLE_APP_ID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          className={`social-button ${googleError ? 'hide-button' : ''}`}
        >
          <div className="box-image">
            <img src="/images/social-network/google.svg" alt="" />
          </div>
          <p>{TRANSLATIONS.auth.googleLogin}</p>
        </SocialButton>
           */}
        <button className="social-button" onClick={googleLogin}>
          <div className="box-image">
            <img src="/images/social-network/google.svg" alt="" />
          </div>
          <p>{TRANSLATIONS.auth.googleLogin}</p>
        </button>
        <SocialButton
          provider="facebook"
          appId={process.env.FACEBOOK_APP_ID}
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
          className="social-button "
          scope={['email']}
        >
          <div className="box-image">
            <img src="/images/social-network/facebook.svg" alt="" />
          </div>
          <p>{TRANSLATIONS.auth.facebookLogin}</p>
        </SocialButton>
      </div>

      <style>{`
      .tooltip-container{
        width:100%;
        display: block!important;
      }
      .modal-overflow {
        overflow: scroll hidden;
      }
      .register-modal{
        text-align:left;
      }
      .form-links {
        display: flex;
        justify-content: space-between;  
        margin-bottom: 1em
      }
      .remember-me {
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
      }
      .forgot-password {
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
      }
      .button {
        margin-bottom: 1em
      }
      .button span {
        margin-left: 5px;
        vertical-align: middle;
      }
      .move-right{
        opacity:0;
        transition:.2s;
        left:50px;
      }
      .h-50{
        height:45px;
      }
      .social-button {
        height: 50px;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        text-align:center;
        text-decoration: none;
        background-color: var(--white);
        color: var(--grey);
        cursor: pointer;
        border: 1px solid #e7e7e7;
        transition:all .3s ease;
      }
      .social-button:hover {
        box-shadow:var(--shadow-light-hover);
        transform: translateY(-1px);
      }
      .social-button p{
        font-weight:500;
      }
      .divider{
        margin-bottom: 15px;
        margin-top:25px;
        padding: 0;
        overflow: visible;
        border: none;
        border-top: 1px solid var(--grey-lighter);
        color: var(--grey-lighter);
        text-align: center;
      }
      .divider:after{
        content: 'Or';
        display: inline-block;
        position: relative;
        top: -7px;
        padding: 0 16px;
        background: var(--background);    
      }
      .social-buttons-container {
        display: flex;
        flex-direction: column;
        gap:15px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        text-align:center;
      }
      .box-image{
        float:left;
        width:20px;
        height:20px;
        left:10px;
        position:relative;
      }
      .modal-overflow .modal{
        overflow: hidden scroll;
      }
      @media only screen and (max-width: 425px) {
        .social-button {
          width: 100%;
        }
        .facebook-button {
          margin-top: 15px;
        }
        .modal-overflow .modal{
          overflow:scroll!important;
          max-height:100%!important;
        }
        .modal h2{
          font-size:22px;
        }
        .modal h4{
          font-size:18px;
        }
        .register-modal{
          padding:20px 0px 50px;
        }
      }
      .hide-button {
        display: none;
      }
      .disabled-social-button {
        border: 1px solid var(--disabled);
        background: var(--disable-bg);
        color: var(--disabled);
        cursor: default;
        width: 100%;
        text-align:center;
      }
      .register-modal{
        text-align:left;
      }
      .container-tooltip {
        width: 100%;
      }
      .hide{
        display:none;
      }
      .register-modal h4{
        margin-top:8px;
      }
      .visible{
        opacity:1;
        transition:all .4s;
        transform:translateX(0px);
      }
      .error{
        border-color:red;
      }
      .form-links {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
      }
      .link a{
        color:var(--primary);
        font-weight:600;
        cursor:pointer;
        font-size:13px;
      }
      .link{
        font-size:13px;
      }
      .register-modal h4{
        font-weight:700;
        margin-bottom:8px;
        font-size:20px;
        color:var(--foreground);
      }
      .discover, .sell, .country{
        margin:6px 0px;
      }
      .discover .radio-group label, .sell .radio-group label{
        margin:8px 0px;
        display: flex;
        align-items: center;
        cursor:pointer;
        font-size:14px;
      }
      .discover .radio-group input, .sell .radio-group input{
        margin-right:16px;
      }
      
      .mobile .options{
        margin:8px 0px;
        display:flex;
        justify-content:space-between;
      }
      .mobile .options > div:first-child{
        flex:2;
      }
      .mobile .options > div{
        flex:3;
      }
      @media all and (min-width: 992px){
        .hide{
          visibility:hidden;
          opacity:0;
          display:block;
          height:0;
          transform:translateX(-50px);
        }
        .maximize .content{
          justify-content:center;
          background:var(--background-utils);
          padding:0;
          margin-top:25px;
          border-radius:8px;
        }
      }
      .maximize{
        width:100%;
        max-width:100%!important;
      }
      :global(input){
        margin-bottom:14px!important;
        padding:30px;
      }
      .link-wrapper {
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
          text-align: center;
        }
      .link-wrapper > a {
        color: var(--primary) !important;
        font-weight: 500;
      }
      `}</style>
    </div>
  );
}

export default LoginForm;
