import Icon from 'components/utils/Icon';

function SidebarItem({ label }) {
  return (
    <>
      <li className="sidebar-item d-f g-10">
        <Icon
          type="check-circle"
          color={'#00E4B8'}
          style={{ marginRight: '10' }}
        />
        <span className="label">{label}</span>
      </li>
      <style jsx>{`
        li {
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
          margin-bottom: 1.5em;
        }
      `}</style>
    </>
  );
}

export default SidebarItem;
